.App {
  height: 100%;
  margin: 0;
}

.page-container {
  background-image: url("./../images/wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 50px;
}

.content-container {
  width: 85%;
  margin: auto;
  text-align: left;
  opacity: 0;
  animation: fade-up 0.5s forwards;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
#home-container p {
  font-size: 1.25em;
  text-align: justify;
}

.profile-image {
  width: 250px;
  height: 250px;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fc6667;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: morphing 10s infinite;
  overflow: hidden;
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}

.profile-intro {
  flex: 1;
  min-width: 225px;
  margin: auto;
  padding-top: 20px;
}

.page-title {
  width: max-content;
  background-image: linear-gradient(rgba(255, 0, 0, 0) 85%, #fc6667 30%);
}

/* Desktop menu */
@media all and (min-width: 1100px) {
  .content-container {
    width: 50%;
  }
  .profile-image {
    width: 250px;
    height: 250px;
  }
  .profile-intro {
    padding-left: 20px;
  }
}
