/* Basic styling */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: "Circular Std", sans-serif;
  background: #fff;
}
nav {
  background: #f3f4f5;
  padding: 5px 20px;
}
ul {
  list-style-type: none;
}
a {
  color: black;
  text-decoration: none;
}
.item {
  opacity: 0;
  animation: fadeInRight 0.5s forwards;
  animation-delay: 0.2s;
}
.item a:hover {
  background-image: linear-gradient(rgba(255,0,0,0) 65%, #FC6667 30%);
}
.logo {
  text-align: left;
}

#nav-logo {
  opacity: 0;
  animation: fadeInRight 0.5s forwards;
}
@keyframes fadeInRight {
  0% {
     opacity: 0;
     transform: translateX(-15px);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}
.logo a:hover {
  text-decoration: none;
}
.menu li {
  padding: 15px 5px;
  white-space: nowrap;
}
.logo a,
.toggle a {
  font-size: 20px;
  color: black;
}
.button.secondary {
  border-bottom: 1px #444 solid;
}

/* Mobile menu */
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.toggle {
  order: 1;
}
.item.button {
  order: 2;
}
.item {
  width: 100%;
  text-align: center;
  order: 3;
  display: none;
}
.item.active {
  display: block;
  text-align: right;
  border-top: solid 1px #444;
}

/* Tablet menu */
@media all and (min-width: 600px) {
  .menu {
    justify-content: center;
  }
  .logo {
    flex: 1;
  }
  .toggle {
    flex: 1;
    text-align: right;
  }
  .item.button {
    width: auto;
    order: 1;
    display: block;
  }
  .toggle {
    order: 2;
  }
  .button.secondary {
    border: 0;
  }
  .button a {
    padding: 7.5px 15px;
    border: 1px solid;
  }
  .button.secondary a {
    background: transparent;
  }
  .button a:hover {
    text-decoration: none;
    cursor: pointer;
    background: #CA140F;;
    color: white;
  }
  .button:not(.secondary) a:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  .button.secondary a:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}

/* Desktop menu */
@media all and (min-width: 900px) {
  .item {
    display: block;
    width: auto;
  }
  .toggle {
    display: none;
  }
  .logo {
    order: 0;
  }
  .item {
    order: 1;
  }
  .button {
    order: 2;
  }
  .menu li {
    padding: 15px 10px;
  }
  .menu li.button {
    padding-right: 0;
  }
  .item.active {
    display: block;
    border: none;
  }
}
