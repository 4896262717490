.post-container h1 {
  margin-bottom: 20px;
}
.post-container h2 {
  background-image: linear-gradient(rgba(255, 0, 0, 0) 85%, #fc6667 30%);
  width: max-content;
  margin-bottom: 15px;
}
.post-container h3 {
  width: max-content;
  margin-bottom: 15px;
}
.post-container h4 {
  color: #ca140f;
  padding-bottom: 20px;
}
.post-container p {
  padding-bottom: 15px;
  font-family: "Circular Std Book", sans-serif;
  text-align: justify;
  font-size: 1.25em;
}
.post-container hr {
  margin-bottom: 15px;
  border: 1px solid #222;
  width: 0;
  animation: hr-grow 0.7s ease forwards;
}

@keyframes hr-grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
